/******************************************************************
Site Name: 
Author: 

Stylesheet: Desktop Stylsheet

This is the desktop size. It's larger than an iPad so it will only
be seen on the Desktop. 

******************************************************************/


.header {	

	.clearHeader {

        > .row {min-height: 120px;@include transition(all 0.3s ease-in-out);}

        #logo {
            width:239px; height:100px;
		    background-size: 239px 100px;
            @include transition(all 0.3s ease-in-out);
        }
		
	}
	 
	.darkHeader {

        > .row {min-height:100px;@include transition(all 0.3s ease-in-out);}

        #logo {
            width: 191px; height: 80px;
		    background-size: 191px 80px;
            @include transition(all 0.3s ease-in-out);
        }

	}
	
	
}


#content {height:auto;}

/*.slick-prev, .slick-next { top: 50%; }
.slick-next { right: 50px; }
.slick-prev { left: 50px; }
.slick-slider .slick-track, .slick-slider .slick-list { -webkit-perspective: 1000px; }*/